<template>
  <div class="calculator">
    <div class="button" :class="{ 'conteudo-oculto': mostrarResultados }">
      <button @click="dosagem" :class="{ active: selected === 'dosagem' }">Dosagem</button>
      <button @click="tempo" :class="{ active: selected === 'tempo' }">Tempo</button>
    </div>
    <div v-if="selected === 'dosagem'">
      <div id="calc-container" :class="{ 'conteudo-oculto': mostrarResultados }">
        <br>
        <h2>Calculadora de Dosagem</h2>

        <form id="formulario" name="formulario">
          <div class="form-inputs">
            <div class="form-control">
              <label for="capMaq">Capacidade da máquina (Ficha):</label>
              <input type="number" name="capMaq" v-model="capMaq" placeholder="Exemplo 280..." required />
              <span v-if="validacaoErro.capMaq" class="ms error-message">Preencha este campo.</span>
            </div>

            <div class="peso">
              <div class="form-control">
                <label for="pesoPeca">Peso da peça (Ficha):</label>
                <input type="number" name="pesoPeca" v-model="pesoPeca" placeholder="Exemplo 0,037..." required
                  step="0.001" />
                <span v-if="validacaoErro.pesoPeca" class="ms error-message">Preencha este campo.</span>
              </div>
              <div class="form-control">
                <label for="pesoCanal">Peso do canal (Ficha):</label>
                <input type="number" name="pesoCanal" v-model="pesoCanal" placeholder="Exemplo 0,017..." required
                  step="0.001" />
                <span v-if="validacaoErro.pesoCanal" class="ms error-message">Preencha este campo.</span>
              </div>
            </div>
            <div class="form-control">
              <label for="nrCavidades">Nº de Cavidades (Ficha):</label>
              <input type="number" name="nrCavidades" v-model="nrCavidades" placeholder="Exemplo 4..." />
              <span v-if="validacaoErro.nrCavidades" class="ms error-message">Preencha este campo.</span>
            </div>
            <div class="form-control">
              <label for="capDosagem">Capacidade de Dosagem (Injetora):</label>
              <input type="number" name="capDosagem" v-model="capDosagem" placeholder="Exemplo 200..." required />
              <span v-if="validacaoErro.capDosagem" class="ms error-message">Preencha este campo.</span>
            </div>
          </div>
        </form>
        <button class="btnCalcular" type="submit" @click.prevent="checkForm">Calcular</button>
      </div>
      <div v-if="mostrarResultados" id="calc-container">
        <br>
        <h2>Resultado do Perfil</h2>
        <div class="resultados">
          <br />
          <div class="form-control">
            <label for="dosagem">Dosagem: {{ parseFloat(dose).toFixed(1) }}</label>
          </div>
          <div class="form-control">
            <label for="iniLenta">Início da Lenta: {{ parseFloat(iniLenta).toFixed(1) }} </label>
          </div>
          <div class="form-control">
            <label for="termLenta">Término da Lenta: {{ parseFloat(termLenta).toFixed(1) }}</label>
          </div>
        </div>
        <br />
        <div>
          <button class="btnVoltar" @click="voltarCalculadora">Voltar</button>
        </div>
      </div>
    </div>
    <!-- Calculadora Tempo -->
    <div v-else>
      <div id="calc-container" :class="{ 'conteudo-oculto': mostrarResultados }">
        <br>
        <h2>Calculadora de Tempos</h2>
        <form id="formulario" name="formulario">
          <div class="form-inputs">
            <div class="form-control">
              <label for="tempoCiclo">Tempo de ciclo (Ficha):</label>
              <input type="number" name="tempoCiclo" v-model="tempoCiclo" placeholder="Exemplo 50..." />
              <span v-if="validacaoErro.tempoCiclo" class="ms error-message">Preencha este campo.</span>
            </div>
            <button class="btnCalcular" @click.prevent="onSubmit">Calcular</button>
          </div>
        </form>
      </div>
      <div v-if="mostrarResultados" id="calc-container">
        <br>
        <h2>Resultado dos Tempos</h2>
        <div class="resultados">
          <br />
          <div class="form-control">
            <label> Parede Fina:</label>
          </div>
          <div class="form-control">
            <label for="recFina">Tempo de Recalque: {{ parseFloat(recFina).toFixed(2) }} s</label>
            <br/>
            <label for="refrgFina">Tempo de Refrigeração: {{ parseFloat(refrigFina).toFixed(2) }} s</label>
          </div>
        </div>
        <br />
        <div class="resultados">
          <br />
          <div class="form-control">
            <label> Parede Grossa:</label>
          </div>
          <div class="form-control">
            <label for="recGrossa">Tempo de Recalque: {{ parseFloat(recGrossa).toFixed(2) }} s</label>
            <br/>
            <label for="refrgGrossa">Tempo de Refrigeração: {{ parseFloat(refrigGrossa).toFixed(2) }} s</label>
          </div>
        </div>
        <br />
        <div>
          <button class="btnVoltar" @click="voltarCalculadora">Voltar</button>
        </div>
      </div>
    </div>
    <div>
      <br/>
      <div class="resultados">
        
          <label>Rafael de Souza (Desenvolvimento) </label><br/>
          <label>Roberto Cabral de Oliveira (Suporte Técnico)</label><br/>
          <label>TIGRE - CORC - Injeção </label><br/>
        
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      selected: 'dosagem',
      capMaq: '',
      pesoPeca: '',
      pesoCanal: '',
      nrCavidades: '',
      capDosagem: '',
      tempoCiclo: '',
      recFina: '',
      refrigFina: '',
      recGrossa: '',
      refrigGrossa: '',
      mostrarResultados: false,
      validacaoErro: {}
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.validacaoErro = [];

      if (!this.tempoCiclo) {
        this.validacaoErro.tempoCiclo = true;

      }
      if (Object.keys(this.validacaoErro).length > 0) {
        return;
      }
      this.calcularTempo();
    },
    checkForm(event) {
      this.validacaoErro = [];
      event.preventDefault()

      if (!this.capMaq) {
        this.validacaoErro.capMaq = true;
        return;
      }

      if (!this.pesoPeca) {
        this.validacaoErro.pesoPeca = true;
        return;
      }
      if (!this.pesoCanal) {
        this.validacaoErro.pesoCanal = true;
        return;
      }
      if (!this.nrCavidades) {
        this.validacaoErro.nrCavidades = true;
        return;
      }
      if (!this.capDosagem) {
        this.validacaoErro.capDosagem = true;
        return;
      }

      if (Object.keys(this.validacaoErro).length > 0) {
        return;
      }

      this.calcDosagem();
    },

    calcDosagem() {

      this.dose = (this.pesoPeca + this.pesoCanal) * 1000 * this.nrCavidades * this.capDosagem / this.capMaq;
      this.iniLenta = this.dose + 4 - (this.pesoCanal * this.nrCavidades * 1000 * this.capDosagem) / this.capMaq;
      this.termLenta = this.dose - 1 - (this.pesoCanal * this.nrCavidades * 1000 * this.capDosagem) / this.capMaq;

      this.mostrarResultados = true;
    },
    calcularTempo() {
      this.recFina = (this.tempoCiclo) * 0.05;
      this.refrigFina = (this.tempoCiclo) * 0.75;

      this.recGrossa = (this.tempoCiclo) * 0.1;
      this.refrigGrossa = (this.tempoCiclo) * 0.6;

      this.mostrarResultados = true;

    },
    dosagem() {
      this.selected = 'dosagem';
    },
    tempo() {
      this.selected = 'tempo';
    },
    voltarCalculadora() {
      this.tempoCiclo = ''
      this.mostrarResultados = false;
    },
    
  }
}

</script>


<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

#app {
  background-color: #102D69;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #fff;
}

.calculator {
  min-width: 400px;
  min-height: 400px;
  background-color: #004b87;
  padding: 2rem;
  border-radius: 10px;
  font-size: small;
}


button {
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
  margin-right: 3px;
  background-color: #ff671f;
  color: #fff;
  opacity: 0.5;
  padding: 1rem 2rem;
}

.button {
  margin-left: 4rem;
  margin-right: 4rem;
  display: block;
  justify-content: center;
}

.btnCalcular {
  background-color: #ff671f;
  opacity: 1;
  color: #fff;
  padding: 1rem 5rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.btnVoltar {
  background-color: #ff671f;
  opacity: 0.9;
  color: #fff;
  padding: 1rem 2rem;
  margin-left: 7rem;

}

button:hover {
  opacity: 2;
}

button.active {
  opacity: 1;
}

.form-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-control {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 1rem;

}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.6rem;
  color: #fff;
}

.form-control input {
  padding: 0.75rem 0.5rem;
}

#calc-container h2 {
  margin-bottom: 2rem;
  text-align: center;
}

.peso {
  width: 100%;
  height: 100%;
  display: flex;
}

.conteudo-oculto {
  display: none;
}

.resultados {
  background-color: #102D69;
  opacity: 0.9;
  color: #dfdfdf;
  padding: 1rem 1rem;
  border-radius: 10px;
}
.ms{ 
    width:auto; 
    padding:0.5em; 
    float:left; 
    display: block;
    margin-bottom:5px;
    font-family: "Lato", sans-serif; 
    font-weight:100; 
    font-size:12px;
    box-sizing: border-box;
  }
  
  .error-message {
  box-shadow: 5px 5px 5px #2b2b2b ;
  background-color:#FFFFBF; 
  border:1px #2b2b2b solid; 
  color:#2b2b2b; 
  border-radius:5px; 
  
}

</style>
