<template>
    <DosagemPage />
</template>

<script>
import DosagemPage from './components/DosagemPage.vue';

export default {
  components: { DosagemPage, },
  
  
}
</script>

<style>

</style>
